body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.loader{
  height: 600px !important;
}
.react-datepicker-wrapper{
  width: 100%;
}
.choosen-select {
  padding-left: 20px !important;
  /* margin-left: 20px; */
}

.choosen-select .css-yk16xz-control {
   background: transparent !important;
  border: none !important;
}
.choosen-select .css-1pahdxg-control {
   background: transparent !important;
  border: none !important;
}
.css-1okebmr-indicatorSeparator{
  background-color:transparent !important;
}
.css-1pahdxg-control{
  border: none !important;
  box-shadow: none !important;
}
.choosen-select .css-1pahdxg-control:focus {
  outline: none;
  background: transparent !important;
  border: none !important;
}

.passwordContainer{
  position: relative;
   display: none;
}
.passwordContainer.show{
 display: block;
}


.showPasswordIcon {
    position: absolute;
    top: 40px;
    right: 8px;
    padding: 0px 5px;
    display: block;
    z-index: 999999;
     cursor: pointer !important;
   
}

.form-group >  span {
    position: absolute;
    left: 7px;
    top: 36px;
    font-size: 20px;
}

span.invalid-feedback {
    position: initial;
    font-size: 15px;
}


.upload-requirements:hover .dropdown-list{
  display: block;
}
.upload-requirements{
  padding-bottom: 10px !important;
}

